import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController, Platform } from '@ionic/angular';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { app, languageInitializer, serverUrl } from '../../../environments/environment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { AddFeatureComponent } from '../add-feature/add-feature.component';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  app: any = { logo: 'logo.png' };
  password_type: string = "password";
  directory = languageInitializer.directory
  public uploader: FileUploader = new FileUploader({});
  profileForm: FormGroup;
  hide = true;
  detail: any;
  companyDetail: { branchID: string; companyID: string; userId: string; };
  countries: any;
  countryCode: any;
  country: any;
  codeToCountry = { "IN": "India", "MY": "Malaysia", "SG": "Singapore", "SA": "Saudi Arabia", "EG": "Egypt" }
  editCountry = 'select country';
  showImageRow: boolean = false;
  appName: any;
  editedDataArray: any = [];
  assign: any = {};
  role = localStorage.getItem('companyRole')
  password: boolean = false;
  isFocused: boolean = false; 
  isSubmitDisabled: boolean = false;
  isPasswordValidLength = false;
  isPasswordValidNumber = false;
  isPasswordValidSpecialCharacter = false;
  isPasswordValidCase = false;
  constructor(
    public platform: Platform,
    private ajaxService: AjaxService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
  ) { }
  
// changes
  onPasswordChange(password: string) {
    this.editSaveData('userPasswd');  /// Existing method call
    this.validatePassword(password);  /// Password validation method
  }
  // check validate the password
  validatePassword(password: string) {
    this.isPasswordValidLength = password.length >= 5;
    this.isPasswordValidNumber = this.hasNumber(password);
    this.isPasswordValidSpecialCharacter = this.hasSpecialCharacter(password);
    this.isPasswordValidCase = this.hasUpperLowerCase(password);

    // Check if all validations are true
    this.isSubmitDisabled = !(this.isPasswordValidLength && this.isPasswordValidNumber &&
                              this.isPasswordValidSpecialCharacter && this.isPasswordValidCase);
  }
  hasNumber(password: string): boolean {
    const regex = /\d/; 
    return regex.test(password); 
  }
  hasSpecialCharacter(password: string): boolean {
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/; 
    return specialCharPattern.test(password); 
  }
  hasUpperLowerCase(password: string): boolean {
    const letterPattern = /[A-Za-z]/; 
  return letterPattern.test(password);
  }
  onFocus() {
    this.isSubmitDisabled = true;
    this.isFocused=true;
  
    this.profileForm.get('password').valueChanges.subscribe((value) => {
      if (this.profileForm.get('password').valid) {
        this.isSubmitDisabled = false; // Enable submit  once valid
      }else {
        this.isSubmitDisabled = true; // Disable 
      }
    });
  }
  // changes
  createForm() {
    this.profileForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
      compId: [''],
      firstName: ['', Validators.required],
      lastName: [''],
      addressLine1: ['',],
      contactNo: ['', Validators.required],
      // inPage: ['', Validators.required ],
      email: ['', Validators.required],
      addressLine2: ['', Validators.required],
      country: [''],
      city: ['', Validators.required],
    });
  }
  getCountries() {
    const url = serverUrl.web + '/login/getPreferences?key=Countries&companyId=' + this.companyDetail.companyID;
    this.ajaxService.ajaxGetPerference(url)
      .subscribe(res => {
        this.countries = res;
      })
  }
  getCountryCode() {
    const url = serverUrl.web + '/login/getPreferences?key=CountryCode&companyId=' + this.companyDetail.companyID;
    this.ajaxService.ajaxGetPerference(url)
      .subscribe(res => {
        this.countryCode = res;
        // this.detail.countryCode
        for (var prop in res) {
          if (res.hasOwnProperty(prop)) {
            if (res[prop] === "IN") {
              this.country = prop;
              this.editCountry = prop;
            }
          }
        }
      })

    const url1 = serverUrl.web + '/login/getPreferences?key=codeToCountry&companyId=""';
    this.ajaxService.ajaxGetPerference(url1)
      .subscribe(res => {
        this.codeToCountry = res;

      })
  }
  submit() {
    var email = "";
    if (this.commonService.mailValidation.test(this.profileForm.value.email))
      email = this.profileForm.value.email
    else {
      this.commonService.presentToast(this.commonService.mailerror)
      return "";
    }
    var jsonMails = {};
    jsonMails["email1"] = email;
    jsonMails["data"] = localStorage.corpId;
    let serviceData = JSON.stringify(jsonMails);

    let url: string = serverUrl.web + "/alert/emailvalidation/" + serviceData;
    this.commonService.presentLoader();
    this.ajaxService.ajaxGetWithString(url)
      .subscribe(res => {
        this.commonService.dismissLoader();
        var json = JSON.parse(res);
        if (!Object.values(json).includes(false)) {
          const userCountry = this.countryCode[this.profileForm.value.country];
          var datas;
          if (this.editedDataArray.includes("userPasswd") && this.profileForm.value.password.trim() != '') {
            datas = {
              "firstName": this.profileForm.value.firstName.trim(),
              "lastName": this.profileForm.value.lastName.trim(),
              "userName": this.profileForm.value.userName,
              "companyId": this.companyDetail.companyID,
              "userPassword": this.profileForm.value.password.trim(),
              "countryCode": userCountry,
              "addressLine1": this.profileForm.value.addressLine1.trim(),
              "addressLine2": this.profileForm.value.addressLine2.trim(),
              "fax": email, "contact": this.profileForm.value.contactNo.trim().replace(/ /g, ""),
              "addressId": (this.detail.addressId).toString(),
              "addressCity": this.profileForm.value.city.trim(),
              "passwordChange": this.editedDataArray.includes("userPasswd")
            }
          } else {
            datas = {
              "firstName": this.profileForm.value.firstName.trim(),
              "lastName": this.profileForm.value.lastName.trim(),
              "userName": this.profileForm.value.userName,
              "companyId": this.companyDetail.companyID,
              // "userPassword": this.profileForm.value.password.trim(),
              "countryCode": userCountry,
              "addressLine1": this.profileForm.value.addressLine1.trim(),
              "addressLine2": this.profileForm.value.addressLine2.trim(),
              "fax": email, "contact": this.profileForm.value.contactNo.trim().replace(/ /g, ""),
              "addressId": (this.detail.addressId).toString(),
              "addressCity": this.profileForm.value.city.trim(),
              "passwordChange": this.editedDataArray.includes("userPasswd")
            }
          }

          datas["branchId"] = localStorage.getItem('corpId');
          datas["oldValue"] = JSON.stringify(this.commonService.userAdminControlEditDelete(this.editedDataArray, this.detail, {}));
          const url1 = serverUrl.web + '/user/set/userdetail'
          this.ajaxService.ajaxPostWithString(url1, datas).subscribe(res => {
            if (res = "successfully Updated") {
              this.profileForm.reset();

              if (this.showImageRow) {
                let url = serverUrl.web + '/site/insert/s3'

                const file: any = this.uploader;
                if (file.queue != 0) {
                  const testData: FormData = new FormData();
                  testData.append('file', file.queue[0]._file);
                  testData.append('data', 'Company');
                  this.ajaxService.ajaxPutMethod2(url, testData)
                    .subscribe(res => {

                      if (res.message == "Success") {
                        this.commonService.presentToast("Logo Uploaded successfully!")
                        url = serverUrl.web + '/global/updatesuperAdminlogo';
                        let name: any = this.uploader.queue[this.uploader.queue.length - 1]._file.name
                        let body = { "suffix": localStorage.companySuffix, "logoUrl": name }
                        this.ajaxService.ajaxPostWithString(url, body)
                          .subscribe(res => {

                          });

                      } else {
                        this.commonService.presentToast("please give an valid details to activate");
                      }
                      // this.commonService.presentToast("please insert one document ");
                    })
                }
              }
              this.getProfile();
              this.commonService.presentToast("successfully Updated");
            }

          })
        } else
          this.commonService.presentToast(this.commonService.mailerror)
      })

      this.isFocused =false;
      this.isPasswordValidLength = false;
      this.isPasswordValidNumber = false;
      this.isPasswordValidSpecialCharacter = false;
      this.isPasswordValidCase = false;
     
  }

  async companyFeature() {
    const modal = await this.modalController.create({
      component: AddFeatureComponent,
      cssClass: 'my-modal-class',
      componentProps: {
        value: this.assign,
        page: "profile"
      }
    });
    modal.onDidDismiss().then(() => {
      this.getProfile()
    })
    return await modal.present();
  }



  getProfile() {
    this.password_type = "password";
    const url = serverUrl.web + '/user/getUserDetails'
    let jsonData = {
      "companyId": this.companyDetail.companyID,
      "userName": this.companyDetail.userId
    }
    this.ajaxService.ajaxPostMethod(url, jsonData).subscribe(res => {

      if (res.hasOwnProperty("logo")) {
        this.showImageRow = true;
      }
      this.detail = res;
      this.assign = Object.assign({ email: this.detail.emailAddress, companyId: this.detail.company_companyId, mainMenu: this.detail.mainMenu, macIp: "", oldValue: "", userId: "", branchId: this.detail.company_companyId, roleName: this.detail.role });
      this.countries
      this.profileForm.patchValue({
        userName: this.detail.emailAddress,
        password: this.detail.userPasswd,
        compId: this.detail.company_companyId,
        firstName: this.detail.firstName,
        lastName: this.detail.lastName,
        addressLine1: this.detail.addressLine1,
        contactNo: this.detail.contactNo,
        email: this.detail.fax,
        addressLine2: this.detail.addressLine2,
        country: this.codeToCountry[this.detail.countryCode],
        city: this.detail.addressCity

      });
      setTimeout(() => {
        this.editedDataArray = [];
      }, 2000)
      this.isSubmitDisabled =false;
    })
  }

  editSaveData(data) {

    this.editedDataArray = this.commonService.userAdminEditArray("edit", data, this.editedDataArray)
  }

  ngOnInit() {
    this.appName = app.appName
    this.companyDetail = {
      branchID: localStorage.getItem('corpId'),
      companyID: localStorage.getItem('corpId'),
      userId: localStorage.getItem('userName')
    }
    this.getProfile();
    this.getCountries();
    this.getCountryCode();
    this.createForm();
    this.app["logo"] = localStorage.companyLogo;
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res;
      });
  }

}
